import React from "react";
import styled from "styled-components";
import {
  logoApple,
  logoGoogle,
  titlePartnership,
  titlePartnershipM,
} from "../../assets/images";
import { PARTNER_DATA } from "./partnerData";
import Footer from "../../components/Footer";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Partnership = () => {
  const getImage = (imgName: string) =>
    require(`../../assets/images/${imgName}`);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const settingsSlider1 = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: -1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    pauseOnHover: false,
    arrows: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const settingsSlider2 = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 2000,
    pauseOnHover: false,
    arrows: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <PartnershipWrapper id="9">
      <h2>
        <img
          src={isTablet ? titlePartnershipM : titlePartnership}
          alt="partnership logo"
        />
      </h2>
      <PartnershipList>
        <Slider {...settingsSlider1}>
          {PARTNER_DATA.map((partner) => {
            if (partner.id < 10) {
              return (
                <img
                  src={getImage(partner.src)}
                  alt={partner.alt}
                  key={partner.id}
                />
              );
            }
          })}
        </Slider>
        <Slider {...settingsSlider2} className="nth_slider">
          {PARTNER_DATA.map((partner) => {
            if (partner.id > 9) {
              return (
                <img
                  src={getImage(partner.src)}
                  alt={partner.alt}
                  key={partner.id}
                />
              );
            }
          })}
        </Slider>
      </PartnershipList>
      <ButtonSpace />
      <ButtonWrap>
        <a
          href="https://play.google.com/store/apps/details?id=com.carrieverse.carriepark"
          target="_blank"
        >
          <img src={logoGoogle} alt="google play logo" />
        </a>
        {/*  <a href="https://apps.apple.com/us/app/carrieverse/id6449248691" target="_blank"><img src={logoApple} alt="app store logo"/></a>*/}
      </ButtonWrap>
      <Footer />
    </PartnershipWrapper>
  );
};

export default Partnership;

const PartnershipWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 72px;
  width: 100vw;
  height: 100vh;
  background: #0b0a0a;
  h2 {
    margin-bottom: 40px;
  }
  @media ${theme.mq.tablet} {
    width: 100%;
    height: auto;
    justify-content: flex-start;
    padding: 40px 0 190px;
    width: 100%;
    h2 {
      img {
        width: 356px;
      }
    }
  }
  @media ${theme.mq.mobile} {
    h2 {
      img {
        width: 238px;
      }
    }
  }
`;

const PartnershipList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    margin: 30px 0;
  }
  .nth_slider {
    .slick-track {
      margin-left: 7.0313vw;
    }
  }
  .slick-slider {
    width: 100%;
    div {
      text-align: center;
      img {
        max-width: 274px;
        width: 14.2708vw;
        height: auto;
      }
    }
  }

  @media ${theme.mq.tablet} {
    box-sizier: border-box;
    .nth_slider {
      .slick-track {
        margin-left: 115px;
      }
    }
    img {
      max-width: 100% !important;
      width: auto !important;
    }
  }
  @media ${theme.mq.mobile} {
    .nth_slider {
      .slick-track {
        margin-left: 135px;
      }
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  a {
    display: block;
    width: 212px;
    img {
      width: 100%;
    }
  }
  @media ${theme.mq.mobile} {
    gap: 8px;

    a {
      width: 140px;
      height: 48px;
    }
  }
`;

const ButtonSpace = styled.div`
  height: 160px;
  @media ${theme.mq.tablet} {
    height: 64px;
  }
`;
