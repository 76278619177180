import React, { useState } from "react";
import styled, { css } from "styled-components";
import { iconMinus, iconPlus, titleFaq, titleFaqM } from "../../assets/images";
import { useMediaQuery } from "react-responsive";
import { theme } from "../../styles/theme";
import { ACCORDION_DATA } from "./QuestionData";

const Faq = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <FaqWrapper id="8">
      <Title>
        <img src={isTablet ? titleFaqM : titleFaq} />
      </Title>
      <AccordionWrap>
        {ACCORDION_DATA.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionHeader onClick={() => toggleAccordion(index)}>
              <AccordionTitle active={activeIndex === index}>
                {item.title}
              </AccordionTitle>
              <AccordionButton>
                <img
                  src={activeIndex === index ? iconMinus : iconPlus}
                  alt={activeIndex === index ? "더보기" : "닫기"}
                />
              </AccordionButton>
            </AccordionHeader>
            <AccordionContent active={activeIndex === index}>
              {item.content}
            </AccordionContent>
          </AccordionItem>
        ))}
      </AccordionWrap>
    </FaqWrapper>
  );
};

export default Faq;

const FaqWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 6.3542vw;
  width: 100%;
  height: 100%;
  background: #0b0a0a;
  text-align: center;

  @media ${theme.mq.tablet} {
    display: flex;
    padding: 16px 0 60px;
    width: 100%;
    height: auto;
  }
`;
const Title = styled.h2`
  width: 100%;
  @media ${theme.mq.tablet} {
    width: 480px;
    img {
      width: 100%;
    }
  }
  @media ${theme.mq.mobile} {
    width: 320px;
  }
`;
const AccordionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  color: #fff;
  @media ${theme.mq.tablet} {
    padding: 0 20px;
    width: auto;
  }
`;

const AccordionItem = styled.div`
  padding: 32px 20px;
  border-bottom: 1px solid #333;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
  @media ${theme.mq.mobile} {
    padding: 24px 12px;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const AccordionButton = styled.button`
  padding: 0;
  margin: 0;
  img {
    display: block;
    width: 32px;
  }
  @media ${theme.mq.mobile} {
    align-self: flex-start;
    img {
      width: 24px;
    }
  }
`;

const AccordionTitle = styled.span<{ active: boolean }>`
  font-size: 2.2rem;
  font-weight: 600;
  ${({ active }) =>
    active &&
    css`
      text-shadow: 0px 0px 10px rgba(49, 255, 156, 0.5),
        0px 0px 8px rgba(49, 255, 156, 0.2);
    `};

  @media ${theme.mq.mobile} {
    font-size: 16px;
    text-align: left;
    white-space: pre-line;
  }
`;

const AccordionContent = styled.div<{ active: boolean }>`
  max-height: ${({ active }) => (active ? "500px" : "0px")};
  overflow: hidden;
  padding-top: ${({ active }) => (active ? "12px" : "0px")};
  transition: max-height 0.5s, padding-top 0.3s ease;
  text-align: start;
  font-size: 2rem;
  > div {
    line-height: 28px;
    .onlyMobile {
      display: none;
    }
    .onlyPc {
      display: block;
    }
    @media ${theme.mq.mobile} {
      line-height: 22px;
      .onlyMobile {
        display: block;
      }
      .onlyPc {
        display: none;
      }
    }
  }
  .answer_list {
    > div:first-child {
      margin-bottom: 16px;
      @media ${theme.mq.mobile} {
        margin-bottom: 8px;
      }
    }
    p {
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 8px;
      @media ${theme.mq.mobile} {
        font-size: 16px;
        margin-bottom: 4px;
      }
    }
    ul {
      display: flex;
      gap: 24px;
      @media ${theme.mq.mobile} {
        display: block;
      }

      li {
        position: relative;
        margin-left: 34px;
        @media ${theme.mq.mobile} {
          margin-left: 25px;
        }
        &::before {
          position: absolute;
          display: block;
          content: "";
          display: block;
          width: 2px;
          height: 4px;
          left: -18px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #fff;
          @media ${theme.mq.mobile} {
            left: -14px;
          }
        }
        .boldText {
          font-weight: 700;
        }
      }
    }
  }

  @media ${theme.mq.mobile} {
    font-size: 16px;
    white-space: pre-line;
  }
`;
