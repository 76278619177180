import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import {
  carrie_logo_gr,
  HeaderNavBottomLight,
  iconDiscodeM,
  iconLogoM,
  iconMediumM,
  iconNavClose,
  iconNavOpen,
  iconTwitterM,
  iconYoutubeM,
} from "../../assets/images";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";

interface PageHandler {
  handlePageChange: (number: number) => void;
  currentPage: number;
}

interface PageActive {
  isActive: boolean;
}

interface navProps {
  navState: boolean;
}
interface menuProps {
  pageNum: number[];
}

export const PAGEMENU = [
  { name: "Home", key: 0, pageNum: [0] },
  { name: "About", key: 1, pageNum: [1] },
  { name: "Land NFT", key: 2, pageNum: [2, 3, 4] },
  { name: "Economy", key: 5, pageNum: [5, 6, 7] },
  { name: "FAQ", key: 8, pageNum: [8] },
  { name: "Partnership", key: 9, pageNum: [9] },
];

const Header = ({ handlePageChange, currentPage }: PageHandler) => {
  const [navState, setNavState] = useState(false);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const navHandle = () => {
    setNavState(!navState);
  };

  const modalHandle = (key: number) => {
    setNavState(false);
    handlePageChange(key);
  };

  const handleNavActive = (menu: menuProps) => {
    return menu.pageNum.includes(currentPage);
  };

  return (
    <HeaderWrapper>
      {isTablet ? (
        <MobileHeaderWrap>
          <button className="nav_open" onClick={navHandle}>
            <img src={iconNavOpen} alt="nav open image" />
          </button>
          <AllNav navState={navState}>
            <Link to={"/"}>
              <img src={iconLogoM} alt="logo mobile image" />
            </Link>
            <ul>
              {PAGEMENU.map((menu, i) => (
                <NavItem key={i} isActive={handleNavActive(menu)}>
                  <Link
                    to={`${menu.key}`}
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={0}
                    onClick={() => modalHandle(menu.key)}
                  >
                    {menu.name}
                  </Link>
                  {/*<NavBottomLight isActive={menu.pageNum.includes(currentPage)}></NavBottomLight>*/}
                </NavItem>
              ))}
            </ul>
            <LinkBox>
              <a
                href="https://youtube.com/@CarrieVerseOfficial"
                target="_blank"
              >
                <img src={iconYoutubeM} alt="youtube icon" />
              </a>
              <a href="https://discord.gg/carrieverseofficial" target="_blank">
                <img src={iconDiscodeM} alt="dicord icon" />
              </a>
              <a href="https://medium.com/carrieverse" target="_blank">
                <img src={iconMediumM} alt="medium icon" />
              </a>
              <a href="https://twitter.com/CarrieVerseNFT" target="_blank">
                <img src={iconTwitterM} alt="twitter icon" />
              </a>
            </LinkBox>
            <button className="nav_close" onClick={navHandle}>
              <img src={iconNavClose} alt="nav close image" />
            </button>
          </AllNav>
        </MobileHeaderWrap>
      ) : (
        <>
          <LogoImgWrapper>
            <Link to={`/`} onClick={() => handlePageChange(0)}>
              <LogoImg />
            </Link>
          </LogoImgWrapper>

          <NavBox>
            {PAGEMENU.map((menu, i) => {
              return i !== 0 ? (
                <NavItem key={i} isActive={handleNavActive(menu)}>
                  <Link
                    to={`#${menu.pageNum}`}
                    onClick={() => handlePageChange(menu.key)}
                  >
                    {menu.name}
                  </Link>
                  {/*<NavBottomLight isActive={menu.pageNum.includes(currentPage)}></NavBottomLight>*/}
                </NavItem>
              ) : (
                ""
              );
            })}
          </NavBox>
        </>
      )}
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 72px;
  z-index: 2000;
`;

const LogoImgWrapper = styled.div`
  position: absolute;
  left: 80px;
  width: 114px;
  height: 28px;
  @media ${theme.mq.tablet} {
    display: none;
  }
`;

const LogoImg = styled.div`
  width: 114px;
  height: 28px;
  background: url(${carrie_logo_gr});
  background-size: cover;
  background-position: top;
`;

const NavBox = styled.ul`
  flex: 1;

  display: flex;
  justify-content: center;

  gap: 48px;
  a {
    cursor: pointer;
  }
`;

const NavItem = styled.li<PageActive>`
  a {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: right;

    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: ${({ isActive }) => (isActive ? "700" : "400")};
    color: ${({ isActive }) => (isActive ? "#F3FEF9" : "#949494")};

    transition: all 0.3s ease;

    :hover {
      font-weight: ${({ isActive }) => (isActive ? "700" : "400")};
      color: #f3fef9;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      //background: url(${HeaderNavBottomLight}) center center no-repeat;
      background: linear-gradient(
        180deg,
        rgba(24, 181, 162, 0) 0%,
        #18b5a2 44.42%,
        rgba(24, 181, 162, 0) 100%
      );
      filter: blur(3px);
      width: ${({ isActive }) =>
        isActive ? "100%" : "0"}; /* 가상 요소 초기 너비를 0으로 설정 */
      height: 2px;
      transition: width 550ms ease-in-out; /* 너비가 바뀔 때 애니메이션 효과 적용 */
      border-radius: 100%;
    }
    &:before {
      display: block;
      content: "";
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%); /* 가상 요소를 수평 방향으로 중앙 정렬 */
      //background: url(${HeaderNavBottomLight}) center center no-repeat;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: blur(0.25px);
      width: ${({ isActive }) =>
        isActive
          ? "calc(100% - 5px)"
          : "0"}; /* 가상 요소 초기 너비를 0으로 설정 */
      height: 2px;
      transition: width 550ms ease-in-out; /* 너비가 바뀔 때 애니메이션 효과 적용 */
      border-radius: 100%;
    }

    &:hover:after,
    &:hover:before {
      width: 100%; /* 가상 요소의 너비를 부모 요소의 100%로 변경하여 양쪽으로 퍼지게 함 */
    }
  }
  @media ${theme.mq.tablet} {
    padding: 30px 0;
    width: 100%;
    text-align: center;
    a {
      display: inline-block;
      font-size: 24px;
      font-weight: 400;
      color: #949494;
      &::after,
      &::before {
        width: 0;
      }
      &.active {
        font-weight: 700;
        color: #f3fef9;
        &::after,
        &::before {
          width: 100%;
        }
      }
    }
  }
`;

const MobileHeaderWrap = styled.div`
  .nav_open {
    position: fixed;
    top: 10px;
    right: 10px;
  }
`;

const AllNav = styled.div<navProps>`
  position: fixed;
  top: 0;
  left: ${({ navState }) => (navState ? "0" : "100%")};
  bottom: 0;
  width: 100%;
  background: #0b0a0a;
  z-index: 1000;
  transition: all ease 0.2s;
  > a {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 120px;
    height: 40px;
    img {
      width: 100%;
    }
  }
  .nav_close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  ul {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
`;

const LinkBox = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 32px;
  transform: translateX(-50%);
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 8px;
    width: 40px;
    height: 40px;
    background: linear-gradient(
        0deg,
        rgba(243, 254, 249, 0.8),
        rgba(243, 254, 249, 0.8)
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 0px 12.5px rgba(49, 255, 156, 0.5),
      0px 0px 50px rgba(49, 255, 156, 0.3),
      inset 0px 0px 5.41667px rgba(49, 255, 156, 0.5);
    border-radius: 100%;
  }
`;
