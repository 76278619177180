import React, { useEffect, useState} from 'react';
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SLIDER_DATA } from "./slideData";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import {titleCarrieverse, titleCarrieverseM} from "../../assets/images";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";

interface bgProps{
  bgSrc : string;
}

const About = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [bgSliderNum, setBgSliderNum] = useState(0);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const settingsSlider = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchMove: true,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    pauseOnHover: false,
    arrows: true,
    swipeToSlide : true,
    focusOnSelect : true,
    asNavFor:nav1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          autoplay: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          autoplay: false,
        },
      },
    ],
  };
  const settingsSliderBg = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    autoplay: false,
    fade: true,
    speed: 500,
    pauseOnHover: false,
    arrows: false,
    cssEase: "ease-in-out",
    asNavFor:nav2,
    afterChange: function(e : number) {
      setBgSliderNum(e);
    }
  };


  const getImage = (imgName: string) => require(`../../assets/images/${imgName}`);
    return (
        <AboutWrapper id="1">
          <Slider
              {...settingsSliderBg}
              ref={(slider1:any) => setNav1(slider1)}
              className="bg_slider"
          >
            {SLIDER_DATA.map((item)=>(
                <SliderBg bgSrc={getImage(item.bgSrc)} key={item.id} />
            ))}
          </Slider>
          <AboutContent>
            <h2><img src={isTablet ? titleCarrieverseM :titleCarrieverse}/></h2>
            <div>
              <p>Carrieverse is a content-rich life-logging <br className="onlyMobile"/>metaverse where you can enjoy and own. <br />
                Its original IP is rooted in 'Carrie and Friends' and <br className="onlyMobile"/>Carrieverse targets multi-generation including <br className="onlyMobile"/>Gen Z living in Web2.</p>
              <p>
                Our mission is to enable metaverse with web3. <br />
                Anyone in Carrieverse can enjoy and web3 will <br className="onlyMobile"/>enable people to contribute to their world with <br className="onlyMobile"/>more colors.
              </p>
            </div>
            <Slider
                {...settingsSlider}
                ref={(slider2:any) => setNav2(slider2)}
            >
              {SLIDER_DATA.map((item)=>(
                  <img src={getImage(item.preViewSrc)} alt="carrieverse image" key={item.id} />
              ))}
            </Slider>
          </AboutContent>

        </AboutWrapper>
    );
};

export default About;

const AboutWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: gray;
  font-size: 4rem;
  .bg_slider{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    div{
      height:100%; 
      span{
        transition: all 3s ease;
        transform: scale(1);
      }
      &.slick-active span {
        transform: scale(1.1);
      }
    }
    
  }
  @media (min-width: 1920px){
    width: 100% !important;
  }
  @media ${theme.mq.tablet}{
    width: 100%;
    height: auto;
  }
`

const SliderBg = styled.span<bgProps>`
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: url(${({bgSrc}) => bgSrc}) no-repeat center center;
  background-size: cover;
`

const AboutContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top:50px;
  text-align: center;
  
  h2{
    width: 100%;
  }
  > div{
    margin-bottom:80px;
    p{
      padding:0 20px;
      font-size: 2.2rem;
      line-height:32px;
      text-shadow: 0.5px 0.5px 1px rgba(0,0,0,0.6);
      color:#fff;
      &:first-child{
        margin-bottom:18px;
      }
      .onlyMobile{
        display: none;
      }
    }
  }
  .slick-slider{
    padding: 0 78px;
    width:922px;
    box-sizing: border-box;
    .slick-list{
      margin:0 -10px;
      .slick-track{
        padding:10px 0;
      }
      .slick-slide{
        padding:0 10px;
        > div{
          position: relative;
          height:136px;
          ::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.6);
            border:1px solid #949494;
            transition: all ease-in-out 0.2s;
          }
          ::before{
            content: "";
            position: absolute;
            left:50%;
            bottom:-2px;
            width: 0;
            height: 4px;
            background: linear-gradient(180deg, rgba(24, 181, 162, 0) 0%, #18B5A2 44.42%, rgba(24, 181, 162, 0) 100%);
            filter: blur(6px);
            border:1px solid #F3FEF9;
            transform: translateX(-50%);
            transition: all ease-in-out 0.5s;
          }
        }
        &.slick-current{
          > div {
            ::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.3);
              box-shadow: 0px 0px 10px rgba(49, 255, 156, 0.5), inset 0px 0px 10px rgba(49, 255, 156, 0.4);
              border: 1px solid #949494;
            }
            ::before{
              width: 120px;
            }
          }
        }
      }
    }
    .slick-dots{
      position: static;
      display: flex !important;
      justify-content: center;
      li{
        display: block;
        margin: 0 4px;
        width: 8px;
        height:8px;
        button{
          padding:0;
          width: 8px;
          height:8px;
          background-color: rgba(255, 255, 255, 0.4);
          border-radius:100%;
          &::before {
            display: none;
          }
        }
        &.slick-active button{
          background-color: #fff;
          box-shadow: 0px 0px 10px rgba(49, 255, 156, 0.7), 0px 0px 30px rgba(49, 255, 156, 0.5), inset 0px 0px 2px rgba(49, 255, 156, 0.5);
          filter: blur(0.25px);
        }
      }
    }
  }
  
  @media ${theme.mq.tablet}{
    position: relative;
    padding:75px 0 0;
    h2{
      margin-bottom: 8px;
      img{
        width: 360px;
        max-width:100%;
      }
    }
    > div{
      margin-bottom:64px;
      max-width:360px;
      p{
        padding:0 5px;
        line-height:26px;
        .onlyMobile{
          display: block;
        }
      }
    }
    .slick-slider{
      margin-bottom:80px;
      padding: 0;
      max-width:100%;
      .slick-dots{
        position: static;
        li button:before{
          width:6px;
          height:6px;
          color:#949494;
          opacity: 1;
        }
        li.slick-active button:before{
          color:#fff;
          box-shadow: 0px 0px 10px rgba(49, 255, 156, 0.7), 0px 0px 30px rgba(49, 255, 156, 0.5), inset 0px 0px 2px rgba(49, 255, 156, 0.5);
          filter: blur(0.25px);
        }
      }
      .slick-list {
        margin:0 0 30px;
        .slick-track{
          margin-left:-25%;
        }
        .slick-slide {
          > div {
            height: auto;
            ::before{
              display: none;
            }
            img {
              display: block !important;
            }
          }
        }
      }
    }
  }
  @media ${theme.mq.mobile}{
    h2{
      margin-bottom: 8px;
      img{
        width:240px;
      }
    }
    > div{
      p{
        font-size:16px;
        line-height:22px;
      }
    }
  }
  @media (max-width: 360px){
  > div{
      p{
        .onlyMobile{
          display: none;
        }
      }
    }
  }
`