import React, { useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  iconDiscord,
  iconMedium,
  iconTwitter,
  iconYoutube,
} from "../../assets/images";
import { theme } from "../../styles/theme";

interface PageProps {
  currentPage: number;
}

interface pageNumProps {
  pageNum: number;
}

const LinkNav = ({ currentPage }: PageProps) => {
  const ulRef = useRef<HTMLUListElement>(null);
  const handleOnWheel = () => {
    console.log("onWheel: scrolling the list...");
  };
  return (
    <LinkBar pageNum={currentPage}>
      <li>
        <a href="https://youtube.com/@CarrieVerseOfficial" target="_blank">
          <img src={iconYoutube} alt="youtube icon" />
        </a>
      </li>
      <li>
        <a href="https://discord.gg/carrieverseofficial" target="_blank">
          <img src={iconDiscord} alt="discord icon" />
        </a>
      </li>
      <li>
        <a href="https://medium.com/carrieverse" target="_blank">
          <img src={iconMedium} alt="medium icon" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/CarrieVerseNFT" target="_blank">
          <img src={iconTwitter} alt="twitter icon" />
        </a>
      </li>
    </LinkBar>
  );
};

export default LinkNav;

const LinkBar = styled.ul<pageNumProps>`
  position: fixed;
  bottom: ${(props) => (props.pageNum === 9 ? "110px;" : "60px;")};
  right: 80px;
  display: flex;
  z-index: 2000;
  transition: all 1s ease;
  li {
    width: 48px;
    height: 48px;
    &:not(:last-child) {
      margin-right: 16px;
    }
    a {
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 100%;
      box-shadow: 0px 0px 15px rgba(49, 255, 156, 0.5),
        0px 0px 60px rgba(49, 255, 156, 0.3),
        inset 0px 0px 6.5px rgba(49, 255, 156, 0.5);
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @media ${theme.mq.tablet} {
    display: none;
  }
`;
