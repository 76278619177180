export const TEST_DATA:{[key: string]: string[]}= {
    A: ["Clicker Games", "Strategic Games"],
    B: ["steady incentives", "incentives differ with strategies"],
    C: ["to play with friends", "to play alone"],
    D: ["decorating my house and avatar", "growing value and leveling up"]
};

export const RESULT_DATA:{[key: string]: string[]}= {
    Residential: ["1111","1121","1211","1221","2111","2121","2211"],
    Industrial: ["1112","1122","1222","2122"],
    Commercial: ["1212","2112","2212","2221","2222"],
}