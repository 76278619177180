export const NFT_LAND_DATA = [
    {
        id:1,
        imageSrc: "landnft_img_01",
        title: "Industrial NFTs"
    },
    {
        id:2,
        imageSrc: "landnft_img_02",
        title: "Commercial NFTs"
    },
    {
        id:3,
        imageSrc: "landnft_img_04",
        title: "Residential NFTs"
    }
];