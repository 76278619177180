import React from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";
import {
    iconCeleb,
    iconCvtx,
    iconFandom,
    iconPfandom,
    iconSwap,
    iconSwapM,
    titleFlow,
    titleFlowM
} from "../../assets/images";

const Flow = () => {
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: 640px)",
    });
    const ICON_FLOW = [
        {id:1, imgSrc: iconCeleb, title: "Celeb", description: "In-game item\n Mini-game reward"},
        {id:2, imgSrc: iconPfandom, title: "pFandom", description: "Utility token\n Means for exchange"},
        {id:3, imgSrc: iconFandom, title: "Fandom", description: "Utility token\n Defi staking"},
        {id:4, imgSrc: iconCvtx, title: "CVTX", description: "Utility token\n Key currency in Cling Platform"},
    ];
    return (
        <FlowWrap id="6">
            <Title>
                <img src={isTablet? titleFlowM : titleFlow} alt="currency flow"/>
            </Title>
            <ContentWrap>
                <ListIconWrap>
                    {ICON_FLOW.map((icon, index) =>
                        <>
                        <ListBox>
                            <img src={icon.imgSrc} alt={icon.title}/>
                        </ListBox>
                        {index < ICON_FLOW.length-1 && (
                            <ArrowBox>
                                <img className={"iconArrow"} src={isMobile ? iconSwapM : iconSwap}/>
                            </ArrowBox>
                        )}
                        </>
                    )}
                </ListIconWrap>
                <ListTextWrap>
                    {ICON_FLOW.map((text, indxx) =>
                        <TextBox>
                            <ListTitle>{text.title}</ListTitle>
                            <ListText>{text.description}</ListText>
                        </TextBox>
                    )}
                </ListTextWrap>
            </ContentWrap>
            <ButtonWrap>
                <a href="https://w3con.carrieverse.io/" target={"_blank"}>W3C</a>
                <a href="https://clingswap.io/" target={"_blank"}>Cling Swap</a>
            </ButtonWrap>
        </FlowWrap>
    );
};

export default Flow;

const FlowWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 93px;
  width: 100vw;
  height: 100vh;
  background: #0B0A0A;

  @media ${theme.mq.tablet}{
    display: flex;
    padding: 20px 0 80px;
    width: 100%;
    height: auto;
    align-items: center;
    gap: 50px;
  }
`;
const Title = styled.h2`
  @media ${theme.mq.tablet}{
    margin-bottom: 0;
    img {
      width: 420px; // /2
    }
  }
  @media ${theme.mq.mobile}{
    img {
      width: 280px; // /3
    }
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 1156px;
  @media ${theme.mq.tablet} {
    width: 100%;
  }
  @media ${theme.mq.mobile}{
    flex-direction: row;
    width: 320px;
    margin: 0 auto;
    gap: 14px;
  }
`;
const ListIconWrap =styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  width:  100%;
  padding: 0 37px;
  
  @media ${theme.mq.tablet}{
    gap: 0;
  }
  @media ${theme.mq.mobile}{
    flex-direction: column;
    width: 100px;
    gap: 0;
    padding: 0;
  }
`;
const ListBox = styled.li`
    display: flex;
    align-items: center;
  img {
    width: 100%;
  }
  @media ${theme.mq.mobile}{
  }
`;
const ListTextWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  padding-bottom: 10px;
  @media ${theme.mq.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
const TextBox = styled.div`
    text-align: center;
  @media ${theme.mq.mobile} {
    margin-top: 10px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    height: 80px;
    text-align: left;
  }
`;
const ListTitle =styled.p`
  font-size: 2.4rem;
  margin-bottom: 16px;
  color: #F3FEF9;
  text-shadow: 0px 0px 10px rgba(49, 255, 156, 0.50), 0px 0px 8px rgba(49, 255, 156, 0.20);
  line-height: 1.33;
  font-weight: 600;
  @media ${theme.mq.mobile} {
    margin-bottom: 6px;
    font-size: 20px;
    line-height: normal;
  }
`;
const ListText = styled.p`
    font-size: 2.2rem;
  white-space: pre-line;
  color: #fff;
  line-height: 1.1;
  @media ${theme.mq.mobile} {
    font-size: 16px;
    line-height: normal
  }
`;
const ButtonWrap = styled.div`
    display: flex;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 48px;
  width: 1156px;
  a {
    display: block;
    color: #fff;
    font-weight: 500;
    line-height: normal;
    font-size: 1.8rem;
    padding: 12px 0;
    border: 1px solid #333;
    background: #1A1919;
    transition: all .3s ease;
    &:hover {
      color: #0B0A0A;
      background: #F3FEF9;
      font-weight: 700;
      box-shadow: 0px 0px 20px 0px rgba(49, 255, 156, 0.20) inset, 0px 0px 50px 0px rgba(49, 255, 156, 0.25), 0px 0px 12px 0px rgba(49, 255, 156, 0.50);
    }
    &:first-child {
      width: 862px;
      @media ${theme.mq.tablet}{
        width: 100%;
      }
    }
    &:last-child {
      width: 274px;
      @media ${theme.mq.tablet}{
        width: 27%;
      }
    }
  }
  @media ${theme.mq.tablet}{
    width: 100%;
    padding: 0 3.6133vw;
    gap: 7.8125vw;
  }
  @media ${theme.mq.mobile} {
    justify-content: center;
    gap: 12px;
    padding-bottom: 0;
    a {
      padding: 13px 0;
      width: 114px !important;
      font-size: 16px;
      background: none;
      border: 1px solid #C4C4C4;
      line-height: 1.25;
    }
  }
`;
const ArrowBox = styled.div`
  width: 80px;
  margin: 0 3.5px;
    img {
      width: 100%;
    }
  @media ${theme.mq.mobile} {
    width: 40px;
  }
`;