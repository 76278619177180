import React, {useState} from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";
import {bgEco, btnAmplify, iconScroll, titleEco, titleEcoM} from "../../assets/images";
import useModal from "../../hooks/useModal";
import CommonModal from "../../components/Modal/commonModal";

const Ecosystem = () => {
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: 640px)",
    });
    const [isModal, openModal, closeModal] = useModal(false);
    const handleModal = () => {
        if(!isModal){
            openModal();
        }else {
            closeModal();
        }
    }
    return (
        <EcosystemWrapper id="5">
            <Title>
                <img src={isTablet? titleEcoM : titleEco} alt="ecosystem"/>
            </Title>
            <ContentWrap>
                <img src={bgEco} />
                {isMobile && (
                    <ButtonWrap onClick={handleModal}>
                        <img src={btnAmplify} />
                    </ButtonWrap>
                )}
            </ContentWrap>
            {isModal && (
                <CommonModal
                    width={"100vw"}
                    minHeight={"100vh"}
                    left={"50%"}
                    top={"50%"}
                    modalIsOpen={isModal}
                    closeModal={closeModal}
                    closeIcon={true}
                    scroll={true}
                >
                    <ImgWrap>
                        <img src={bgEco} />
                    </ImgWrap>
                    <ScrollText>
                        <img src={iconScroll}/>
                        Scroll left and right
                        <img src={iconScroll} style={{transform: 'rotate(180deg)'}} />
                    </ScrollText>
                </CommonModal>
            )}

        </EcosystemWrapper>
    );
};

export default Ecosystem;

const EcosystemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #0B0A0A;

  @media ${theme.mq.tablet}{
    display: flex;
    padding: 20px 20px 80px;
    width: 100%;
    height: auto;
    align-items: center;
  }
`;
const Title = styled.h2`
  margin-bottom: 32px;
  @media ${theme.mq.tablet}{
    margin-bottom: 16px;
    img {
      width: 420px; // /2
    }
  }
  @media ${theme.mq.mobile}{
    img {
      width: 280px; // /3
    }
  }
`;
const ContentWrap = styled.div`
  position: relative;
  padding-bottom: 48px;
  img {
    width: 1262px;
  }
  @media ${theme.mq.tablet}{
    padding: 25px 10px;
    width: 100%;
    img {
      width: 100%;
    }
  }
`;
const ButtonWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  background: rgba(11, 10, 10, 0.6);
  
  img {
    width: 35vw;
  }
`;
const ImgWrap = styled.div`
    position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  img {
    display: block;
    height: 100%;
    padding: 50px 20px;
  }
`;
const ScrollText = styled.div`
  position: fixed;
  left: 50%;
  bottom: 50px;
  display: flex;
  align-items: center;
  gap: 8px;
    font-size: 20px;
  transform: translateX(-50%);
  white-space: nowrap;
`;