import React, {useState} from 'react';
import styled from "styled-components";
import {titleUtility, titleUtilityM} from "../../assets/images";
import {useMediaQuery} from "react-responsive";
import {theme} from "../../styles/theme";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Utility = () => {
    const getImage = (imgName: string, isHovered?: boolean) => {
        const finalName = isHovered? `${imgName}_on.png`: `${imgName}.svg`;
        return require(`../../assets/images/${finalName}`);
    };
    const ICON_MENU = [
        {id:1,imgSrc:"icon_utility_01",title:"Exclusive incentive"},
        {id:2, imgSrc:"icon_utility_02",title:"Rental service"},
        {id:3, imgSrc:"icon_utility_03", title:"On/Off mode" },
        {id:4,imgSrc:"icon_utility_04", title:"Level up and \n" +
                "maximize earning"},
        {id:5, imgSrc:"icon_utility_05", title:"Property value appreciation \n" +
                "like real world"}
    ];
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const onMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };
    const onMouseLeave = () => {
        setHoveredIndex(-1);
    };

    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });

    const settingsSlider = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchMove: true,
        centerMode: true,
        centerPadding: "0px",
        speed: 500,
        pauseOnHover: false,
        arrows: false,
        swipeToSlide : true,
        focusOnSelect : true,
        dots: true,
    };

    return (
        <UtilityWrapper id={"3"}>
            <ContentWrap>
                <Title><img src={isTablet? titleUtilityM:titleUtility} alt="utilities of land NFT" /></Title>
                {isTablet?
                    <Slider {...settingsSlider}>
                        {ICON_MENU.map((icon,i) =>
                            <SlideBox key={icon.id}>
                                <img src={getImage(icon.imgSrc, true)} alt=""/>
                                <p>{icon.title}</p>
                            </SlideBox>
                        )}
                    </Slider>
                    :
                    <IconWrap>
                        {ICON_MENU.map((menu,i) =>
                            <li key={i}><a href="#" onClick={(e) => e.preventDefault()} onMouseEnter={()=>onMouseEnter(i)} onMouseLeave={onMouseLeave}>
                                <img src={getImage(menu.imgSrc, hoveredIndex === i)}/>
                                <span>{menu.title}</span>
                            </a></li>)
                        }
                    </IconWrap>
                }

            </ContentWrap>
        </UtilityWrapper>
    );
};

export default Utility;

const UtilityWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #0B0A0A;

  @media ${theme.mq.tablet}{
    display: flex;
    padding: 20px 0 80px;
    width: 100%;
    height: auto;
    align-items: center;
  }
`;

const ContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top:50px;
  text-align: center;
  @media ${theme.mq.tablet}{
    position: relative;
    width:100%;
    padding:0;
  }

  .slick-slider{
    margin-top: -20px;
    padding: 0;
    max-width:100%;
    box-sizing: border-box;
    .slick-list{
      margin:0 0 32px;
      .slick-slide{
        padding:0 10px;
        > div{
          position: relative;
          height: auto;

          img {
            display: block !important;
            margin: 60px auto 24px;
          }
        }

      }
    }
    .slick-dots{
      position: static;
      display: flex !important;
      justify-content: center;
      li{
        display: block;
        margin: 0 4px;
        width: 8px;
        height:8px;
        button{
          padding:0;
          width: 8px;
          height:8px;
          background-color: #949494;
          border-radius:100%;
          &::before {
            display: none;
          }
        }
        &.slick-active button{
          background-color: #fff;
          box-shadow: 0px 0px 10px rgba(49, 255, 156, 0.7), 0px 0px 30px rgba(49, 255, 156, 0.5), inset 0px 0px 2px rgba(49, 255, 156, 0.5);
          filter: blur(0.25px);
          &::before{
            color:#fff;
            box-shadow: 0px 0px 10px rgba(49, 255, 156, 0.7), 0px 0px 30px rgba(49, 255, 156, 0.5), inset 0px 0px 2px rgba(49, 255, 156, 0.5);
            filter: blur(0.25px);
          }
        }
      }
    }
  }
 `
const Title = styled.h2`
  width: 100%;
  margin-bottom: 45px;
  @media ${theme.mq.tablet}{
    margin-bottom: 0;
    img{
      width: 496px;
    }
  }
  @media ${theme.mq.mobile}{
    
    img {
      width: 330px;
    }
  }
`;
const IconWrap = styled.ul`
  width: 1240px;
  height: auto;
  display: flex;
  justify-content: center;
  column-gap: 173px;
  flex-wrap: wrap;
  li {
    width: 253px;
    margin-bottom: 30px;
  }
  
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    line-height: normal;
    font-weight: 600;
    img {
      display: block;
      width: 180px;
      height: 180px;
      margin-bottom: 24px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: all .5s ease;
    }
    &:hover {
      img {
        border: 2px solid #F3FEF9;
        box-shadow: 
                0px 0px 10px 0px rgba(49, 255, 156, 0.50), 
                0px 0px 10px 0px rgba(49, 255, 156, 0.40) inset;
      }
    }
    span {
      white-space: pre-line;
    }
    
  }
`

const SlideBox = styled.div`
  
  width: 232px;
  display: flex;
  img {
    width: 120px;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(49, 255, 156, 0.5),
    0px 0px 10px rgba(49, 255, 156, 0.3),
    inset 0px 0px 6.5px rgba(49, 255, 156, 0.5);
  }
   p {
     color: #fff;
     font-size: 20px;
     font-weight: 600;
     white-space: pre-line;
   }
`;