import styled from "styled-components";
import {bgCvtx, bgCvtxM, landNftGif, titleLandNft, titleLandNftM} from "../../assets/images";
import {theme} from "../../styles/theme";
import {useState} from "react";
import {useMediaQuery} from "react-responsive";

const getImage = (imgName: string) => {
    return require(`../../assets/images/${imgName}.png`)
};

const LandNft = () => {
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const onMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };
    const onMouseLeave = () => {
        setHoveredIndex(-1);
    };
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });

    return (
        <LandNftWrapper id="2">
            <ContentWrap>
                <Inner>
                    <Title><img src={isTablet? titleLandNftM:titleLandNft} alt="land NFT logo" /></Title>
                    <LandNftContent>
                        Land NFTs, with 3 different categories, allow you<br className="onlyMobile"/> to own
                        industrial, commercial, and residential <br className="onlyMobile"/>facilities that form the
                        foundation of the <br className="onlyMobile"/>economic system within Carrieverse.
                        <br/><br/>
                        Enjoy the financial benefits of the NFTs within <br className="onlyMobile"/>both Carrieverse
                        and real life.
                    </LandNftContent>
                    <ButtonWrap>
                        <a href="https://oneplanetnft.io/collections/carrieverse-land-nft" target="_blank">Buy The Land</a>
                    </ButtonWrap>
                </Inner>

            </ContentWrap>
            <BgBox>
                <img src={landNftGif} alt="Land NFT gif image" />
            </BgBox>
        </LandNftWrapper>
    );
};

export default LandNft;

const LandNftWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 4rem;
  background:#0B0A0A;
  @media ${theme.mq.tablet}{
    display: flex;
    flex-wrap:wrap;
    flex-direction: column-reverse;
    padding: 40px 0 80px;
    width: 100%;
    height: auto;
    align-items: center;
  }
`;
const ContentWrap = styled.div`
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-right:20px;
  width: 50%;
  z-index: 100;
  @media ${theme.mq.tablet}{
    position: relative;
    width:100%;
    padding:0;
    align-items: center;
  }
`;

const Inner = styled.div`
  padding-left:40px;
  width: 608px;
  max-width:100%;
  @media ${theme.mq.tablet}{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    width: 100%;
  }
`;
const Title = styled.h2`
  margin-left: -50px;
  margin-bottom: -18px;

  @media ${theme.mq.tablet}{
    margin: 0 0 8px;
    img{
      width: 283px;
    }
  }
  @media ${theme.mq.mobile}{
    img {
      width: 189px;
    }
  }
`;
const LandNftContent =styled.p`
  margin-bottom: 40px;
  font-size: 2.2rem;
  line-height: 32px;
  color: #fff;
  .onlyMobile{
    display: none;
  }
  .onlyPc {
    display: block;
  }
  @media ${theme.mq.tablet}{
    line-height:23px;
    .onlyMobile{
      display: block;
    }
    .onlyPc {
      display: none;
    }
  }
  @media ${theme.mq.mobile} {
    font-size: 16px;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
    >a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 146px;
      height: 48px;
      font-size: 1.8rem;
      color:#fff;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      transition: all ease 0.3s;
      &:hover {
        color: #0B0A0A;
        font-weight: 700;
        background: #F3FEF9;
        box-shadow:
                inset 0px 0px 20px 0px rgba(49, 255, 156, 0.3),
                0px 0px 40px 0px rgba(49, 255, 156, 0.3),
                0px 0px 10px 0px rgba(49, 255, 156, 0.5);
      }

      @media ${theme.mq.tablet}{
        width: 240px;
        height: 48px;
        color: #0B0A0A;
        background:#F3FEF9;
        font-weight: 700;
        font-size: 16px;
        border: none;
        background: #F3FEF9;
        box-shadow: 
                  0px 0px 20px 0px rgba(49, 255, 156, 0.30) inset, 
                  0px 0px 40px 0px rgba(49, 255, 156, 0.30), 
                  0px 0px 10px 0px rgba(49, 255, 156, 0.50);
      }

    }
`;
const BgBox = styled.div`
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  padding-left: 60px;
  width: 50%;
  overflow: hidden;
  img {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    max-width: 508px;
  }
  @media ${theme.mq.tablet}{
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0,0);
      width: 83.3333vw;
    }
  }
  @media ${theme.mq.mobile}{
    img {
      width: 300px;
    }
  }
`;
