export const ACCORDION_DATA = [
    {
        title: "What does the Land NFT do in Carrieverse?", content: (
                <div>
                    With Land NFTs, you can engage in various economic activities in Carrieverse. <br/>
                    CARRIEVERSE Land NFTs have 3 different types. <br/>
                    Residential NFTs with daily incentive plus social-and-earn aspects.<br/>
                    Industrial NFTs with constant and steady selling and earning, more with leveling up your NFT.<br/>
                    Commercial NFT provides earnings according to the demand and supply of real-time Carrieverse users’s activity and also can increaseable depending on their level.
                </div>
        )
    },
    {
        title: "How can I get Land NFTs?", content: (
                <div>
                    You can only obtain Land NFTs by minting on the launchpad or NFT marketplaces. <br/>
                    The total supply is limited at 1,000. <br/>
                    Another way is to rent Land NFTs in-game through the rental system which allows you to share the in-game profits with the landlord.
                </div>
        )
    },
    {
        title: "NFT Mint Breakdown", content: (
                <div className="answer_list">
                    <div>
                    <p>Out of 1,000</p>
                    <ul>
                        <li>1st sale : 90</li>
                        <li>2nd sale : TBD</li>
                        <li>3rd sale : TBD</li>
                        <li>Marketing : 40</li>
                    </ul>
                    </div>
                    <div>
                    <p>Out of 1,000</p>
                    <ul>
                        <li>Residential NFTs : 400</li>
                        <li>Commercial NFTs : 400</li>
                        <li>Industrial NFTs : 200</li>
                    </ul>
                    </div>
                </div>
        )
    }, {
        title: "How can I use Land NFTs?", content: (
                <div>
                    We'll announce another bridge website where you can make your NFT available on-off game using the
                    'Cling wallet'. <br/>
                    This bridge website will appear here as a button when the in-game NFT economy system is available and we will notify everyone through our Twitter and Discord. <br/>
                    More detailed information will be delivered through the play book.
                </div>
        )
    },{
    title: "Are Land NFTs refundable?", content: (
        <div>
            ALL NFTs are non-refundable.
        </div>
        )
    }, {
    title: "How many Land NFTs I could in Carrieverse game?", content: (
        <div>
            All Land NFT is applicable without limits except residential NFTs.<br/>
            However, please be aware every account accept <span className={"boldText"}>only one residential NFT</span> on the CARRIEVERSE.
        </div>
        )
    }
];