import React from "react";
import styled from "styled-components";
import { iconArrowLeftOff } from "../../assets/images";
import { iconArrowLeftOn } from "../../assets/images";
interface PrevArrowProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
// 타입스크립트를 사용하기 때문에 onClick 이벤트를 props로 받아준다.
// className을 받아줄 수 도 있다. 그리고 부모 컴포넌트에서 설정해 줘도 된다.

export default function PrevArrow({ onClick }: PrevArrowProps) {
  return <PrevBtn className="prev-arrow" onClick={onClick}></PrevBtn>;
}

const PrevBtn = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: url(${iconArrowLeftOff}) no-repeat;
  transition: all ease 0.2s;
  &:hover {
    background: url(${iconArrowLeftOn}) no-repeat;
    cursor: pointer;
  }
`;
