import React from 'react';
import styled from "styled-components";
import { CarrieverseMainVideo } from '../../assets/videos';
import {IntroTextImg, IntroTextImgM} from "../../assets/images";
import {theme} from "../../styles/theme";

const Intro = () => {



    return (
        <IntroWrapper id="0">
            <ReactPlayerWrapper>
                <video autoPlay loop muted playsInline>
                    <source src={CarrieverseMainVideo} type="video/mp4"/>
                </video>
                <IntroOpacityBg/>
            </ReactPlayerWrapper>
            <IntroText/>
        </IntroWrapper>
    );
};

export default Intro;

const IntroWrapper = styled.div`
  position: relative;
  
  width: 100vw;
  height: 100vh;
  @media ${theme.mq.tablet}{
    width: 100%;
  }
`

const IntroOpacityBg = styled.div`
  position: absolute;
  
  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.6);
  
  z-index: 1000;
`

const IntroText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 708px;
  height: 255px;
  background-image: url("${IntroTextImg}");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1001;
  
  @media ${theme.mq.tablet}{
    width:500px;
    height:180px;
    max-width:100%;
  }
  @media ${theme.mq.mobile}{
    width:332px;
    height:132px;
    max-width:100%;
    background-image: url("${IntroTextImgM}");
  }
`

const ReactPlayerWrapper = styled.div`
  position: relative;
  
  width: 100%;
  height: 0;
  
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  
  video {
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100vw;
    height: 100vh;
    
    object-fit: cover;

    @media (max-width: 768px) {
      width: auto;
      height: auto;
    }
  }
  @media ${theme.mq.tablet}{
    height: 100%;
    overflow: hidden;
    video{
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);

      height:calc(100% + 1px);
    }
  }
`;
