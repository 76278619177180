// Header
export const HeaderNavBottomLight = `${process.env.REACT_APP_IMG_ADDRESS}images/HeaderNavBottomLight.png`;
export const carrie_logo_gr = `${process.env.REACT_APP_IMG_ADDRESS}images/carrie_logo_gr.png`;

// SNS Area
export const iconDiscord = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_discord.svg`;
export const iconTwitter = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_twitter.svg`;
export { default as iconYoutube } from "./icon_youtube.svg";
export { default as iconMedium } from "./icon_medium.svg";

// Intro
export const IntroTextImg = `${process.env.REACT_APP_IMG_ADDRESS}images/IntroTextImg.png`;
export const IntroTextImgM = `${process.env.REACT_APP_IMG_ADDRESS}images/IntroTextImgM.png`;

// About
export const titleCarrieverse = `${process.env.REACT_APP_IMG_ADDRESS}images/title_carrieverse.png`;
export const titleCarrieverseM = `${process.env.REACT_APP_IMG_ADDRESS}images/title_carrieverse_m.png`;
export const iconArrowLeftOff = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_arrow_left_off.png`;
export const iconArrowLeftOn = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_arrow_left_on.png`;
export const iconArrowRightOff = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_arrow_right_off.png`;
export const iconArrowRightOn = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_arrow_right_on.png`;

// Land NFT
export { default as titleLandNft } from "./title_landnft.png";
export { default as titleLandNftM } from "./title_landnft_m.png";
export { default as landNftGif } from "./landnft_main.gif";

// Land Utility
export { default as titleUtility } from "./title_utility.png";
export { default as titleUtilityM } from "./title_utility_m.png";

// Land Test
export { default as titleLandTest } from "./title_landtest.png";
export { default as titleLandTestM } from "./title_landtest_m.png";
export { default as testArrowL } from "./arrow_landnft_left.png";
export { default as testArrowR } from "./arrow_landnft_right.png";
export { default as testArrowM } from "./arrow_landnft_m.png";
export { default as landTestGif } from "./landtest_main_m.gif";

// Test Page
export { default as titleTest } from "./title_ilove.png";
export { default as titleTestM } from "./title_ilove_m.png";

// Economy
export { default as titleEco } from "./title_ecosystem.png";
export { default as titleEcoM } from "./title_ecosystem_m.png";
export { default as titleFlow } from "./title_flow.png";
export { default as titleFlowM } from "./title_flow_m.png";
export { default as bgEco } from "./main_ecosystem.png";
export { default as iconCeleb } from "./flow_celeb_2.png";
export { default as iconPfandom } from "./flow_pfandom.png";
export { default as iconFandom } from "./flow_fandom.png";
export { default as iconCvtx } from "./flow_cvtx.png";

// CVTX
export { default as bgCvtx } from "./bg_cvtx_img.png";
export { default as bgCvtxM } from "./bg_cvtx_img_m.png";
export { default as titleCvtx } from "./title_cvtx.png";
export { default as titleCvtxM } from "./title_cvtx_m.png";
export { default as iconSwap } from "./icon_flow_arrow.png";
export { default as iconSwapM } from "./icon_flow_arrow_m.png";

// Faq
export { default as titleFaq } from "./title_faq.png";
export { default as titleFaqM } from "./title_faq_m.png";
export { default as iconPlus } from "./icon_faq_plus.png";
export { default as iconMinus } from "./icon_faq_minus.png";

// Partnership
export { default as titlePartnership } from "./title_partnership_v2.png";
export { default as titlePartnershipM } from "./title_partner_m_v2.png";
export { default as logoGoogle } from "./icon_googleplay.png";
export { default as logoApple } from "./icon_appstore.png";

// Modal
export { default as btnClose } from "./icon_modal_close.svg";
export { default as btnBack } from "./icon_modal_back.svg";
export { default as btnAmplify } from "./btn_amplify.png";
export { default as iconScroll } from "./icon_scroll_arrow.svg";

//Mobile
export const iconLogoM = `${process.env.REACT_APP_IMG_ADDRESS}images/img_mobile_logo.png`;
export const iconNavOpen = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_nav_open.svg`;
export const iconNavClose = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_nav_close.svg`;
export const iconDiscodeM = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_discord_black.svg`;
export const iconTwitterM = `${process.env.REACT_APP_IMG_ADDRESS}images/icon_twitter_black.svg`;
export { default as iconYoutubeM } from "./icon_youtube_black.svg";
export { default as iconMediumM } from "./icon_medium_black.svg";
