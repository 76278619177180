import React from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";

const Footer = () => {
  return (
      <FooterWrap>
        <p>Carrieverse CO.,LTD. All RIGHTS RESERVED</p>
        <ul>
          <li>Contact</li>
          <li>contact@Carrieverse.world</li>
        </ul>
      </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.footer`
  position: absolute;
  left:0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items:center;
  text-align:center;
  height:72px;
  background-color:#1A1919 ;
  p{
    width: 50%;
    font-size:1.4rem;
    color:#949494;
  }
  ul{
    display: flex;
    justify-content: center;
    margin: auto;
    li{
      padding:0 8px;
      font-size:1.4rem;
      color:#949494;
      &:last-child{
        position: relative;
        &::after{
          content:"";
          position: absolute;
          top:50%;
          left:0;
          width: 1px;
          height:10px;
          background-color: #949494;
          transform: translateY(-50%);
        }
      }
    }
  }
  @media ${theme.mq.tablet}{
    flex-wrap:wrap;
    align-content: center;
    height:114px;
    p{
      margin-bottom:6px;
      width:100%;
      font-size:14px;
    }
    ul li{
      font-size:14px;
    }
  }
`