import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import styled from "styled-components";
import ReactPageScroller from "react-page-scroller";
import About from "../About";
import Partnership from "../Partnership";
import Intro from "../Intro";
import LinkNav from "../../components/LinkNav";
import { useMediaQuery } from "react-responsive";
import { theme } from "../../styles/theme";
import LandNft from "../LandNft";
import Cvtx from "../Cvtx";
import Utility from "../Utility";
import LandTest from "../LandTest";
import Faq from "../Faq";
import Ecosystem from "../Ecosystem";
import Flow from "../Flow";

const Main = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showTestPage, setShowTestPage] = useState(false);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const handlePageChange = (number: number) => {
    setCurrentPage(number);
  };

  const handleBeforePageChange = (number: number) => {
    setCurrentPage(number);
    if (number !== 4) {
      setTimeout(() => {
        setShowTestPage(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (window.location.hash === "#economy") {
      handlePageChange(6);
    }
  }, []);

  return (
    <MainWrapper>
      <Header handlePageChange={handlePageChange} currentPage={currentPage} />
      {isTablet ? (
        <>
          <Intro />
          <About />
          <LandNft />
          <Utility />
          <LandTest
            showTestPage={showTestPage}
            setShowTestPage={setShowTestPage}
          />
          <Ecosystem />
          <Flow />
          <Cvtx />
          <Faq />
          <Partnership />
        </>
      ) : (
        <ReactPageScroller
          customPageNumber={currentPage}
          onBeforePageScroll={handleBeforePageChange}
          renderAllPagesOnFirstRender
        >
          <Intro />
          <About />
          <LandNft />
          <Utility />
          <LandTest
            showTestPage={showTestPage}
            setShowTestPage={setShowTestPage}
          />
          <Ecosystem />
          <Flow />
          <Cvtx />
          <Faq />
          <Partnership />
        </ReactPageScroller>
      )}

      <LinkNav currentPage={currentPage} />
    </MainWrapper>
  );
};

export default Main;

const MainWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  @media ${theme.mq.tablet} {
    overflow: hidden;
    width: 100%;
    height: auto;
  }
`;
