export const SLIDER_DATA = [
  {
    id: 1,
    preViewSrc : "img_slide01.png",
    bgSrc : "bg_carrie_img01.png"
  },
  {
    id: 2,
    preViewSrc : "img_slide02.png",
    bgSrc : "bg_carrie_img02.png"
  },
  {
    id: 3,
    preViewSrc : "img_slide03.png",
    bgSrc : "bg_carrie_img03.png"
  },
  {
    id: 4,
    preViewSrc : "img_slide04.png",
    bgSrc : "bg_carrie_img04.png"
  },
  {
    id: 5,
    preViewSrc : "img_slide05.png",
    bgSrc : "bg_carrie_img05.png"
  },
  {
    id: 6,
    preViewSrc : "img_slide06.png",
    bgSrc : "bg_carrie_img06.png"
  }
]