import React, {useEffect} from "react";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {landTestGif, testArrowL, testArrowM, testArrowR, titleLandTest, titleLandTestM} from "../../assets/images";
import {theme} from "../../styles/theme";
import {NFT_LAND_DATA} from "./slideData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestPage from "../TestPage";
import CommonModal from "../../components/Modal/commonModal";
import useModal from "../../hooks/useModal";

const LandTest = ({ showTestPage, setShowTestPage }: { showTestPage: boolean, setShowTestPage: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const getImage = (imgName: string) => require(`../../assets/images/${imgName}.png`);
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });


    const handleTestPage =(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setShowTestPage(!showTestPage);
        if(isTablet){
            handleModal();
        }
    }
    const handleModal = () => {
        if(!isModal){
            openModal();
        }else {
            closeModal();
        }
    }
    // 팝업시 스크롤 막기
    useEffect(() => {
        if (showTestPage &&isTablet) {
            document.body.style.overflow = 'hidden';
        } else {
            window.onscroll = null;
            document.body.style.overflow = 'unset';
        }
    }, [showTestPage]);

    const [isModal, openModal, closeModal, selectedOptions, setSelectedOptions] =
        useModal(false, {
            A: "",
            B: "",
            C: "",
            D: "",
        });

    return (
        <LandTestWrapper id={"4"}>
            <ContentWrap>
                <Title><img src={isTablet? titleLandTestM:titleLandTest} /></Title>
                {isTablet?
                    <MobileImgWrap>
                        <img src={landTestGif} alt="quick land test gif image"/>
                    </MobileImgWrap>
                    :
                    <ImgWrap>
                        {NFT_LAND_DATA.map((img,i) => <img key={img.id} src={getImage(img.imageSrc)} alt={img.title}/>)}
                    </ImgWrap>
                }

                <LandTestContent>
                    Click and Find Your Land NFT Type!
                </LandTestContent>
                <ButtonWrap>
                    {isTablet&& (<img src={testArrowM} alt={"arrow icon"}/>)}
                    <button onClick={handleTestPage}>Start</button>
                </ButtonWrap>

            </ContentWrap>
            {showTestPage&&
                    (isModal && isTablet?(
                            <CommonModal
                                width={"100vw"}
                                minHeight={"100vh"}
                                left={"50%"}
                                modalIsOpen={isModal}
                                closeModal={closeModal}
                            >
                                <MobileTestWrap><TestPage showTestPage={showTestPage} setShowTestPage={setShowTestPage}/></MobileTestWrap>
                            </CommonModal>
                        )
                        :
                        (<div className={"show_page"}>
                            <TestPage showTestPage={showTestPage} setShowTestPage={setShowTestPage}/>
                        </div>))

            }
        </LandTestWrapper>
    );
};

export default LandTest;

const LandTestWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 4rem;
  background:#0B0A0A;
  overflow: hidden;
  @media ${theme.mq.tablet}{
    padding-bottom: 80px;
    width: 100%;
    height: auto;
  }
  .show_page {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background:#0B0A0A;
    opacity: 1;
    animation: fade-in 0.5s forwards;
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    
    @media ${theme.mq.tablet}{
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 9999;
    }
  }
  
`;

const MobileTestWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ContentWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top:50px;
  text-align: center;
  @media ${theme.mq.tablet}{
    position: relative;
    width:100%;
    padding:0;
  }
`;
const Title = styled.h2`
  width: 100%;
  @media ${theme.mq.tablet}{
    width: 480px;
    img{
      width: 100%;
    }
  }
  @media ${theme.mq.mobile} {
    width: 320px;
  }
`;
const ImgWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 862px;
  margin-bottom: 72px;
  img {
    width: 274px;
  }
`;
const MobileImgWrap = styled.div`
  width: 300px;
  img {
    width: 100%;
    display: block;
  }
  @media ${theme.mq.mobile}{
    
  }
  
`;
const LandTestContent = styled.p`
  color:#fff;
  font-size: 2.2rem;
  line-height: 32px;
  margin-bottom: 60px;
  font-weight: 600;
  .onlyMobile{
    display: none;
  }
  .onlyPc {
    display: block;
  }
  @media ${theme.mq.tablet}{
    margin-bottom: 0;
    padding-top: 18px;
    line-height:23px;
    font-size: 20px;
    .onlyMobile{
      display: block;
    }
    .onlyPc {
      display: none;
    }
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 74px;
  background-image: url(${testArrowL}), url(${testArrowR});
  background-position: left 18px center, right 18px center;
  background-repeat: no-repeat, no-repeat;
  background-size: 200px, 200px;
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    border: 1px solid #fff;
    font-size: 2.2rem;
    font-weight: 700;
    transition: all ease 0.3s;
    :hover{
      color:#000;
      background:#F3FEF9;
      border:1px solid rgba(49, 255, 156, 0.2);
      box-shadow: 
              0px 0px 15px rgba(49, 255, 156, 0.5), 
              0px 0px 60px rgba(49, 255, 156, 0.3), 
              inset 0px 0px 30px rgba(49, 255, 156, 0.3);
    }
    
  }

  @media ${theme.mq.tablet}{
    background: none;
    flex-direction: column;
    width: 240px;
    height: auto;
    img {
      width:126px;
      margin: -10px 0;
    }
    button {
      width: 100%;
      padding: 14px 0;
      color:#0B0A0A;
      background:#F3FEF9;
      font-size: 16px;
      border:1px solid rgba(49, 255, 156, 0.2);
      box-shadow:
              0px 0px 15px rgba(49, 255, 156, 0.5),
              0px 0px 60px rgba(49, 255, 156, 0.3),
              inset 0px 0px 30px rgba(49, 255, 156, 0.3);
    }
  }
`
