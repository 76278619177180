export const PARTNER_DATA = [
  {
    id: 1,
    src: "img_partner15.png",
    alt: "dmcc logo"
  },
  {
    id: 2,
    src: "img_partner16.png",
    alt: "bybit logo"
  },
  {
    id: 3,
    src : "img_partner01.png",
    alt : "polygon logo"
  },
  {
    id: 4,
    src : "img_partner02.png",
    alt : "polygon labs logo"
  },
  {
    id: 5,
    src: "img_partner17.png",
    alt: "Internet Computer logo"
  },
  {
    id: 6,
    src: "img_partner18.png",
    alt: "THETAN ARENA logo"
  },
  {
    id: 7,
    src: "img_partner19.png",
    alt: "BURRITO logo"
  },
  {
    id: 8,
    src : "img_partner13.png",
    alt : "one planet logo"
  },
  {
    id: 9,
    src : "img_partner20.png",
    alt : "BingX logo"
  },
  {
    id: 10,
    src : "img_partner12.png",
    alt : "CERTIK logo"
  },
  {
    id: 11,
    src : "img_partner09.png",
    alt : "YULIVERSE logo"
  },
  {
    id: 12,
    src : "img_partner04.png",
    alt : "NFTb logo"
  },
  {
    id: 13,
    src : "img_partner10.png",
    alt : "POPSLINE logo"
  },
  {
    id: 14,
    src : "img_partner11.png",
    alt : "SAC logo"
  },
  {
    id: 15,
    src : "img_partner05.png",
    alt : "KINGDOM STORT HEROESWAR logo"
  },
  {
    id: 16,
    src : "img_partner06.png",
    alt : "HORIZON LAND logo"
  },
  {
    id: 17,
    src : "img_partner08.png",
    alt : "MINTWAY unified NFT Portal Service logo"
  },
  {
    id: 18,
    src : "img_partner03.png",
    alt : "TNT logo"
  },
  {
    id: 19,
    src : "img_partner07.png",
    alt : "FORTUNADE logo"
  },
  {
    id: 20,
    src : "img_partner14.png",
    alt : "HAVAH logo"
  },
  {
    id: 21,
    src : "img_partner24.png",
    alt : "pomerium logo"
  },
  {
    id: 22,
    src : "img_partner21.png",
    alt : "MINE WARZ logo"
  },
  {
    id: 23,
    src : "img_partner22.png",
    alt : "OFPrompt logo"
  },
  {
    id: 24,
    src : "img_partner23.png",
    alt : "dew logo"
  }
]