import { useState,Dispatch, SetStateAction } from "react";
type SelectedOptions = {
    [key: string]: string;
};
function useModal(initialValue:boolean, initialSelectedOptions?:SelectedOptions) {
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>(initialSelectedOptions || {});
    const [isOpen, setIsOpen] = useState(initialValue);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        if(selectedOptions){
            setSelectedOptions({...selectedOptions,A:"",B:"",C:"",D:""});
        }
    };

    const switchStatus = () => {
        setIsOpen((prev: boolean) => !prev);
    };


    return [isOpen, openModal, closeModal, selectedOptions, setSelectedOptions] as const;
}

export default useModal;