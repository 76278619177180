import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset};
  html {
    font-size: 62.5%;
    @media ${({ theme }) => theme.mq.tablet} {
      font-size: 50%;
    }
    @media ${({ theme }) => theme.mq.mobile} {
      font-size: 37.5%;
      @supports (-webkit-touch-callout: none) {
    }
  }
  body {

    overflow: hidden;
    background: white;
    color: #333333;
    font-family: Rajdhani, sans-serif;

    * {
      box-sizing: border-box;
      line-height: normal;
    }

    //::-webkit-scrollbar {
    //  display: none;
    //}
    a {
      text-decoration: none;
      color: white;
      font-family: Rajdhani, sans-serif;
    }

    button {
      cursor: pointer;
      border: none;
      background: none;
      color: white;
      font-family: Rajdhani, sans-serif;
    }

    @media ${({theme}) => theme.mq.tablet} {
      overflow: visible;
    }
  }
`;
