import React, {ChangeEvent, useState} from 'react';
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {btnBack, titleTest, titleTestM} from "../../assets/images";
import {RESULT_DATA, TEST_DATA} from "./testData";
import CommonModal from "../../components/Modal/commonModal";
import {theme} from "../../styles/theme";
import useModal from "../../hooks/useModal";

type LabelBoxBgProps ={
    check: boolean;
}
interface TestPageProps {
    showTestPage: boolean;
    setShowTestPage: React.Dispatch<React.SetStateAction<boolean>>;
}
const TestPage: React.FC<TestPageProps> = ({showTestPage, setShowTestPage}) => {

    const getImage = (imgName: string) => require(`../../assets/images/${imgName}`);
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });
    const [isModal, openModal, closeModal, selectedOptions, setSelectedOptions] =
        useModal(false, {
            A: "",
            B: "",
            C: "",
            D: "",
        });
    const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [name]: value,
        }));
    };
    const handleClickBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowTestPage(false);
    }
    const checkAllOptions = () => {
        return Object.values(selectedOptions).every((value) => value !== "");
    };
    const checkOptions =() => {
        const currentOptions = selectedOptions[currentKey];
        return currentOptions !== undefined && currentOptions !== "";
    }
    const handleResults = () => {
        const result = Object.values(selectedOptions).map((item) => Number(item)+1).join("");
        const findKey = Object.keys(RESULT_DATA).find(key => RESULT_DATA[key].includes(result));
        findKey && handleModal(findKey);
    }


    //result modal
    const [modalInfo, setModalInfo] = useState({
        title: "",
        imgSrc: "",
    });

    const handleModal = (key:string) => {
        if(!isModal){
            openModal();
            setModalInfo({...modalInfo, title: `${key} NFTs`, imgSrc: `modal_${key.toLowerCase()}.png`});
        }else {
            closeModal();
        }
    }

    const [currentKey, setCurrentKey] = useState("A");
    const keys = Object.keys(TEST_DATA);
    const currentIndex = keys.indexOf(currentKey);
    const handleClickNext = () => {
        if (currentIndex !== -1 && currentIndex < keys.length - 1) {
            setCurrentKey(keys[currentIndex + 1]);
        }
    }


    return (
        <TestPageWrapper>
            {isTablet? (<>
                    <TestPageMobile>
                    <ButtonBack href="#" onClick={handleClickBack}><img src={btnBack} alt={"back button"}/>Back</ButtonBack>
                    <ListNumberMobile><span>0{currentIndex+1}</span>/ 04</ListNumberMobile>
                    <Title><img src={titleTestM} alt="i love ..."/></Title>
                    <TestListWrap>
                        {TEST_DATA[currentKey].map((item,index) => (
                            <li key={index}>
                                <InputWrap>
                                    <RadioBox>
                                        <LabelBox htmlFor={`${currentKey}-${index}`} check={selectedOptions[currentKey] === index.toString()}>
                                            {item}
                                        </LabelBox>
                                        <input
                                            name={currentKey}
                                            id={`${currentKey}-${index}`}
                                            value={index.toString()}
                                            type="radio"
                                            checked={selectedOptions[currentKey] === index.toString()}
                                            onChange={handleOptionChange}
                                        />
                                    </RadioBox>
                                </InputWrap>
                            </li>
                            ))}
                    </TestListWrap>
                    </TestPageMobile>
                    {currentIndex !== 3? <ButtonNext onClick={handleClickNext} disabled={!checkOptions()}>Next</ButtonNext> : <ResultButton onClick={handleResults} disabled={!checkAllOptions()}>Get Results</ResultButton>}
                </>
            ): (<>
                <Title><img src={titleTest} alt="i love ..."/></Title>
                <TestListWrap>
                    {Object.entries(TEST_DATA).map(([key,values]) => (
                        <li key={key}>
                            <ListNumber>{key}.</ListNumber>
                            <InputWrap>
                                {values.map((content, index) => (
                                    <RadioBox key={index}>
                                        <LabelBox htmlFor={`${key}-${index}`} check={selectedOptions[key] === index.toString()}>
                                            {content}
                                        </LabelBox>
                                        <input
                                            id={`${key}-${index}`}
                                            name={key}
                                            value ={index.toString()}
                                            checked={selectedOptions[key] === index.toString()}
                                            type="radio"
                                            onChange={handleOptionChange}
                                        />

                                    </RadioBox>
                                ))}
                            </InputWrap>
                        </li>
                    ))}
                </TestListWrap>
                <ResultButton onClick={handleResults} disabled={!checkAllOptions()}>Get Results</ResultButton>
            </>)}

            {isModal &&
                (
                    <CommonModal
                        width={isTablet? "100vw":"764px"}
                        minHeight={isTablet? "100vh":"500px"}
                        top={"50%"}
                        left={"50%"}
                        modalIsOpen={isModal}
                        closeModal={closeModal as () => void}
                        closeIcon={isTablet? false : true}
                    >
                        <ModalContent>
                            <img src={getImage(modalInfo.imgSrc)} alt={`${modalInfo.title} Image`}/>
                            <p>The best type that matches with you is</p>
                            <ModalTitle>{modalInfo.title}</ModalTitle>
                            <a href="https://oneplanetnft.io/collections/carrieverse-land-nft" target="_blank">Buy The Land</a>
                            {isTablet && (<ButtonBack className={"position_center"} href="#" onClick={handleClickBack}><img src={btnBack} alt={"back button"}/>Home</ButtonBack>)}
                        </ModalContent>
                    </CommonModal>
                )
            }
        </TestPageWrapper>
    );
};

export default TestPage;

const TestPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom:72px;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background:#0B0A0A;
  
  @media ${theme.mq.tablet}{
    padding: 0 20px 20px;
    width: 100%;
    height: 100%;
    background:#0B0A0A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const TestPageMobile = styled.div`
    width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h2`
    margin-bottom: 50px;
  @media ${theme.mq.tablet}{
    width: 135px;
    img {
      width: 100%;
    }
  }
  @media ${theme.mq.mobile}{
    width: 90px;
    margin-bottom: 30px;
  }
`
const TestListWrap = styled.ul`
  width: 862px;
  margin-bottom: 62px;
  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    
    &:last-child {
      margin-bottom: 0;
    }
    @media ${theme.mq.tablet}{
      margin-bottom: 16px;
    }
  }
  
  @media ${theme.mq.tablet}{
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    
  }
`
const ListNumber = styled.span`
  position: absolute;
  left: -50px;
  color: #F3FEF9;
  text-shadow: 0px 0px 10px 0px rgba(49, 255, 156, 0.50), 0px 0px 8px 0px rgba(49, 255, 156, 0.20);
  font-size: 2.4rem;
  font-weight: 700;
`
const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 74px;
  box-sizing: border-box;
  
  
`;
const RadioBox = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;
  height: 100%;
  color: #949494;
  text-align: center;
  font-size: 2.2rem;
  @media ${theme.mq.tablet}{
    width: 100%;
  }
  @media ${theme.mq.mobile}{
    font-size: 16px;
  }
  
  &:first-child {
    input[type="radio"]{
      border-right: 1px solid #333;
      @media ${theme.mq.tablet}{
        border-width:2px
      }
    }
  }
  &:last-child{
    input[type="radio"]{
      border-left: 1px solid #333;
      @media ${theme.mq.tablet}{
        border-width:2px
      }
    }
  }

  input[type="radio"] {
    -webkit-appearance: none; /* Chrome, Safari, Opera */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Rest of the browsers */
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #333;
    box-shadow: none;
    transition: all .3s ease;
    cursor:pointer;
    border-radius: unset;
  }

  input[type="radio"]:checked {
    border: 2px solid #F3FEF9;
    box-shadow: 0px 0px 10px 0px rgba(49, 255, 156, 0.50), 0px 0px 10px 0px rgba(49, 255, 156, 0.40) inset;
  }
  input[type="radio"]:checked +label {
    text-shadow: 0px 0px 10px 0px rgba(49, 255, 156, 0.50), 0px 0px 8px 0px rgba(49, 255, 156, 0.20);
  }
`;

const LabelBox = styled.label<LabelBoxBgProps>`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color : ${({check}) => check? "#F3FEF9": "#949494"};
  background-color: ${({ check }) => (check ? "#0B0A0A" : "#1A1919")};
  text-shadow: ${({check}) => check? "0px 0px 10px rgba(49, 255, 156, 0.50), 0px 0px 8px rgba(49, 255, 156, 0.20)" : "none"};
  @media ${theme.mq.tablet}{
    font-weight: ${({check}) => check? "700": "600"};
    
  }
`;

const ResultButton = styled.button`
  width: 372px;
  height: 72px;
  font-weight: 700;
  font-size: 2.2rem;
  color: #fff;
  border: 1px solid #FFF;
  transition: all 0.3s ease;
  &:disabled {
    font-weight: 600;
    box-shadow: none;
    border: 1px solid #333;
    background: #1A1919;
    color: #949494;
    &:hover {
      font-weight: 600;
      box-shadow: none;
      border: 1px solid #333;
      background: #1A1919;
      color: #949494;
    }
  }
  &:hover {
    font-weight: 700;
    color:#0B0A0A;
    background:#F3FEF9;
    border: 1px solid transparent;
    box-shadow: 0px 0px 20px 0px rgba(49, 255, 156, 0.30) inset, 0px 0px 40px 0px rgba(49, 255, 156, 0.30), 0px 0px 10px 0px rgba(49, 255, 156, 0.50);
  }
  
  @media ${theme.mq.tablet}{
    width: 100%;
    height: 56px;
    font-weight: 700;
    font-size: 2.2rem;
    color: #0B0A0A;
    background: #F3FEF9;
    border: 1px solid transparent;
    box-shadow: 0px 0px 20px 0px rgba(49, 255, 156, 0.30) inset, 0px 0px 40px 0px rgba(49, 255, 156, 0.30), 0px 0px 10px 0px rgba(49, 255, 156, 0.50);
    transition: all 0.3s ease;
    margin-top: 146px;
    &:disabled {
      box-shadow: none;
      border: 1px solid #333;
      background: #1A1919;
      color: #949494;
    }
    @media ${theme.mq.mobile}{
      font-size: 16px !important;
      margin-top: auto;
      align-self: flex-end;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  font-size: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 274px;
  }
  a {
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 48px;
    margin: 0 auto;
    font-size: 1.8rem;
    color: #fff;
    border: 1px solid #C4C4C4;
    transition: all .3s ease;
    box-sizing: border-box;
    
    &:hover {
      color: #0B0A0A;
      font-weight: 700;
      background: #F3FEF9;
      border: 1px solid transparent;
      box-shadow:
              0px 0px 15px 0px rgba(49, 255, 156, 0.30) inset,
              0px 0px 30px 0px rgba(49, 255, 156, 0.30),
              0px 0px 10px 0px rgba(49, 255, 156, 0.50);
    }
  }
  @media ${theme.mq.tablet}{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    flex:1;
    > img {
      width: 320px;
      margin-bottom: 16px;
    }
    a {
      width: 240px;
      height: 48px;
      font-weight: 700;
      color: #0B0A0A;
      background: #F3FEF9;
      border: 1px solid transparent;
      box-shadow:
              0px 0px 15px 0px rgba(49, 255, 156, 0.30) inset,
              0px 0px 30px 0px rgba(49, 255, 156, 0.30),
              0px 0px 10px 0px rgba(49, 255, 156, 0.50);
    }
  }
  @media ${theme.mq.mobile}{
    p {font-size: 16px;}
    a {font-size: 16px;}
  }
`;
const ModalTitle = styled.p`
  margin:8px 0 32px;
  font-size: 2.6rem;
  font-weight: 700;
  color: #F3FEF9;
  text-shadow: 
          0px 0px 10px rgba(49, 255, 156, 0.50),
          0px 0px 8px rgba(49, 255, 156, 0.20);
  @media ${theme.mq.tablet}{
    margin-bottom: 40px;
  }
  @media ${theme.mq.mobile}{
    font-size: 20px !important;
  }  

`;

//Mobile 추가
const ButtonBack = styled.a`
  margin-left: -20px;
  display: flex !important;
  align-items: center !important;
  padding: 20px !important;
  margin-bottom: 40px !important;
  color: #949494 !important;
  font-size: 2.2rem !important;
  align-self: flex-start !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  font-weight: 700;
  @media ${theme.mq.mobile}{
    font-size: 16px !important;
  }
  >img {
    width: auto !important;
  }
  &.position_center {
    margin: 60px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;
const ListNumberMobile = styled.div`
    color: #fff;
    font-size: 16px;
  margin-bottom: 80px;
  span {
    color: #F3FEF9;
    font-size: 40px;
    margin-right: 8px;
    font-weight: 700;
    text-shadow: 0px 0px 10px rgba(49, 255, 156, 0.50), 0px 0px 8px rgba(49, 255, 156, 0.20);
  }
`;
const ButtonNext = styled.button`
  width: 100%;
  height: 56px;
  font-weight: 700;
  font-size: 2.2rem;
  color: #0B0A0A;
  background: #F3FEF9;
  border: 1px solid transparent;
  box-shadow:
          0px 0px 15px 0px rgba(49, 255, 156, 0.30) inset,
          0px 0px 30px 0px rgba(49, 255, 156, 0.30),
          0px 0px 10px 0px rgba(49, 255, 156, 0.50);
  transition: all 0.3s ease;
  margin-top: 146px;
  &:disabled {
    box-shadow: none;
    border: 1px solid #333;
    background: #1A1919;
    color: #949494;
  }
  @media ${theme.mq.tablet}{
    
  }
  @media ${theme.mq.mobile}{
    font-size: 16px;
  }
`;