import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import styled, {css} from "styled-components";
import { create } from "zustand";
import {btnClose} from "../../assets/images";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";
import useViewPortSize from "../../hooks/useViewPortSize";

interface IModalStyleProps {
    minHeight: string;
    maxHeight?:string;
    height?: string;
    width: string;
    maxWidth?: string;
    transform?: string;
    top: string;
    left: string;
    background?: string;
    closeIcon? : boolean;
    closeText? : string;
}

interface ModalProps {
    width: string;
    maxWidth?: string;
    minHeight: string;
    maxHeight?:string;
    height?: string;
    transform?: string;
    top?: string;
    left: string;
    background?: string;
    children: React.ReactNode;
    modalIsOpen: boolean;
    closeModal: () => void;
    changePhase?: (str: string) => void;
    closeIcon?: boolean;
    closeText?: string;
    scroll?: boolean;
}

export const customStyles = {
    overlay: {
        zIndex: "9999",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
};
export const useModalStore = create<any>((set: any) => ({
    isModalOpen: false,
    setIsModalOpen: (isModalOpen: string) =>
        set(() => {
            return { isModalOpen: isModalOpen };
        }),
}));

const CommonModal = ({
                         children,
                         minHeight,
                         maxHeight,
                         width,
                         maxWidth,
                         height,
                         background,
                         top,
                         left,
                         transform,
                         closeModal,
                         modalIsOpen,
                         changePhase,
                         closeIcon,
                         closeText,
                         scroll,
                     }: ModalProps) => {
    const { setIsModalOpen } = useModalStore();

    const {viewHeight} = useViewPortSize();

    useEffect(() => {
        if (modalIsOpen) {
            setIsModalOpen(true);
        }
    }, [modalIsOpen]);

    const handleClose = () => {
        if (changePhase) changePhase("");
        setIsModalOpen(false);
        closeModal();
    };
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });


    return (
        <ModalWrapper
            width={width}
            maxWidth={maxWidth}
            height={isTablet? `${viewHeight}px`: "auto"}
            minHeight={isTablet? `${viewHeight}px`: minHeight}
            maxHeight={maxHeight}
            background={background}
            top={top ? top : "50%"}
            left={left? left: "50%"}
            transform={transform ? transform : "translate(-50%, -50%)"}
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={handleClose}
            onAfterOpen={() => {
                document.body.style.overflow = "hidden";
            }}
            onAfterClose={() => {
                document.body.style.overflow = "unset";
            }}
            style={customStyles}
        >
            <CloseButton onClick={handleClose}>
                {closeIcon && (<CustomImg src={btnClose} alt="closeIcon" />)}
                {closeText&& (<span>Back</span>)}
            </CloseButton>
            <ChildrenArea>{children}</ChildrenArea>
        </ModalWrapper>
    );
};
export default CommonModal;

const ModalWrapper = styled(Modal)<IModalStyleProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: ${(props) => props.transform};
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => props ? props.background : "#0B0A0A"};
  outline: none;
  color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 9999;
`;

const ChildrenArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  padding-top: 24px;
  background: #0B0A0A;
  overflow-y: hidden;
  overflow-x: visible;
`;

const CloseButton = styled.button`
  z-index: 9999;
  background-color: #0B0A0A;
  color: #949494;
  span {
    position: fixed;
    font-size: 16px;
    top: 20px;
    left: 50px;
  }
`;

const CustomImg = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  @media ${theme.mq.tablet}{
    top: 60px;
    left:auto;
    right: 15px;
    transform: translateY(calc(-100% - 10px));
  }
`;