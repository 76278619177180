import styled from "styled-components";
import {bgCvtx, bgCvtxM, titleCvtx, titleCvtxM} from "../../assets/images";
import {theme} from "../../styles/theme";
import {useState} from "react";
import {useMediaQuery} from "react-responsive";

const Cvtx = () => {
    const getImage = (imgName: string, isHovered?: boolean) => {
        const finalName = isHovered? `${imgName}_bk`: imgName;
        return require(`../../assets/images/${finalName}.png`)
    };
    const ICON_MENU = [
        {name:"icon_gate",url:"https://www.gate.io",title:"Gate.io"},
        {name:"icon_mexc",url:"https://www.mexc.com",title:"MEXC Global"},
        {name:"icon_probit", url:"https://www.probit.com", title:"Probit Global" },
        {name:"icon_bitmart", url:"https://www.bitmart.com", title:"Bitmart"}];

    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const onMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };
    const onMouseLeave = () => {
        setHoveredIndex(-1);
    };
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });

    return (
        <CvtxWrapper id="7">
            <ContentWrap>
            <Inner>
                <Title><img src={isTablet? titleCvtxM : titleCvtx} /></Title>
                <CvtxContent>
                    $CVTX is native token that enhances Carrieverse <br className="onlyMobile"/>
                    experience while empowering its ecosystem. <br className="onlyMobile"/>
                    Upgrade your Land NFTs with $CVTX for value <br className="onlyMobile"/>
                    accrual in earning or exchange your profits from <br className="onlyMobile"/>
                    Land NFTs into $CVTX.
                </CvtxContent>
                <ButtonWrap>
                    {ICON_MENU.map((menu,i) =>
                        <li key={i}><a href={menu.url} target="_blank" onMouseEnter={()=>onMouseEnter(i)} onMouseLeave={onMouseLeave}>
                            <img src={getImage(menu.name, hoveredIndex === i)}/>
                            {isTablet? "":<span>{menu.title}</span>}
                        </a></li>)
                    }
                </ButtonWrap>
            </Inner>

            </ContentWrap>
            <BgBox>
                <img src={isTablet? bgCvtxM:bgCvtx} alt="cvtx background image" />
            </BgBox>
        </CvtxWrapper>
    );
};

export default Cvtx;

const CvtxWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 4rem;
  background:#0B0A0A;
  @media ${theme.mq.tablet}{
    display: flex;
    gap: 40px;
    flex-wrap:wrap;
    flex-direction: column-reverse;
    padding: 40px 0 80px;
    width: 100%;
    height: auto;
    align-items: center;
  }
`;
const ContentWrap = styled.div`
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-right:20px;
  width: 50%;
  z-index: 100;
  @media ${theme.mq.tablet}{
    position: relative;
    width:100%;
    padding:0;
    align-items: center;
  }
`;

const Inner = styled.div`
  padding-left:40px;
  width: 608px;
  max-width:100%;
  @media ${theme.mq.tablet}{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    width: 100%;
  }
`;
const Title = styled.h2`
    margin-left: -50px;
  margin-bottom: -18px;
  
  @media ${theme.mq.tablet}{
    margin: 0;
    width: 354px;
    img{
      width: 100%;
    }
  }
  @media ${theme.mq.mobile}{
    width: 236px;
  }
`;
const CvtxContent =styled.p`
  margin-bottom: 40px;
  font-size: 2.2rem;
  line-height: 32px;
  color: #fff;
  .onlyMobile{
    display: none;
  }
  .onlyPc {
    display: block;
  }
  @media ${theme.mq.tablet}{
    line-height:23px;
    .onlyMobile{
      display: block;
    }
    .onlyPc {
      display: none;
    }
  }
  @media ${theme.mq.mobile}{
    font-size: 16px;
  }
`;
const ButtonWrap = styled.ul<{ title?: string }>`
    display: flex;
  li {
    >a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 133px;
      height: 48px;
      font-size: 1.6rem;
      color:#fff;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      transition: all ease 0.3s;
      font-weight: 600;
      img {
        width: 20px;
        margin-right: 8px;
      }
      :hover {
        color: #0B0A0A;
        background: #F3FEF9;
        box-shadow:
                inset 0px 0px 20px 0px rgba(49, 255, 156, 0.3),
                0px 0px 40px 0px rgba(49, 255, 156, 0.3),
                0px 0px 10px 0px rgba(49, 255, 156, 0.5);
      }
    }
    &:not(:last-child) {
      margin-right: 12px;
    }

    @media ${theme.mq.tablet}{
      >a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border-color: #c4c4c4;
        img {
          margin: 0;
        }
        :hover {
          background: #F3FEF9;
          box-shadow:
                  0px 0px 5.416666030883789px 0px #31FF9C80 inset,
                  0px 0px 50px 0px #31FF9C4D,
                  0px 0px 12.5px 0px #31FF9C80;
        }
      }

    }
  }
`;
const BgBox = styled.div`
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  padding-left: 60px;
  width: 50%;
  overflow: hidden;
  img {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    max-width: 600px;
  }
  @media ${theme.mq.tablet}{
    position: relative;
    width: 100%;
    padding: 0;
    display: flex; 
    justify-content: center;
    align-items: center;
    img {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0,0);
      width: 100%;
    }
  }
  @media ${theme.mq.mobile}{
    width: 320px;
  }
`;
